import http from "../http-common";

class AuthService {
    signIn(user) {
        return http.post(
        "/auth/signin",
        {
            username: user.username,
            password: user.password
        }).then(response => {
            if (response.data.accessToken) {
                let expiry = new Date()
                expiry.setHours(expiry.getHours() + 23)
                response.data.expiry = expiry
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        }).catch(reason => {
            return reason
        });
    }

    signOut() {
        localStorage.removeItem('user');
    }

    signUp(user) {
        return http.post("/auth/signup", user);
    }

    hasRole(user, role) {
        if (user) {
            return user.permissions.map(p => p.role.name).indexOf(role) !== -1
        } else {
            return false
        }
    }
}

export default new AuthService();