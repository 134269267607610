<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-primary">
      <router-link to="/" class="navbar-brand">
        <img src="@/assets/viadirekt-weiss-ohne-bg.png" alt="Mandant3" height="40" style="padding-left: 20px;">
      </router-link>
      <div class="navbar-nav mr-auto">
        <div v-if="user" class="nav-item">
          <router-link to="/mandants" class="nav-link text-white">Ihre Mandanten</router-link>
        </div>
        <div v-if="isSuperuser" class="nav-item">
          <router-link to="/mandant-add" class="nav-link text-white">Mandant erstellen</router-link>
        </div>
        <div v-if="isSuperuser" class="nav-item">
          <router-link to="/users" class="nav-link text-white">Alle Benutzer</router-link>
        </div>
        <div v-if="isSuperuser" class="nav-item">
          <router-link to="/user-add" class="nav-link text-white">Benutzer erstellen</router-link>
        </div>
        <div v-if="isImmunkarteCreator || isImmunkartePrinter" class="nav-item">
          <router-link to="/immunkartes" class="nav-link text-white">Alle Immunkarten</router-link>
        </div>
        <div v-if="isImmunkarteCreator" class="nav-item">
          <router-link to="/immunkarte-add" class="nav-link text-white">Immunkarte erstellen</router-link>
        </div>
        <div v-if="isRezeptCreator || isRezeptProcessor" class="nav-item">
          <router-link to="/rezepts" class="nav-link text-white">Alle Rezepte</router-link>
        </div>
        <div v-if="isRezeptCreator" class="nav-item">
          <router-link to="/rezept-add" class="nav-link text-white">Rezepte hochladen</router-link>
        </div>
      </div>
      <div class="flex-grow-1">
      </div>
      <div v-if="user" class="d-flex me-5">
        <router-link to="/user-info" class="nav-link text-white">{{ user.forename }} {{ user.surname }}</router-link>
      </div>
    </nav>

    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
import AuthService from "./services/auth.service";

export default {
  name: "app",

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    isImmunkarteCreator() {
      return AuthService.hasRole(this.user, "ImmunkarteCreator")
    },

    isImmunkartePrinter() {
      return AuthService.hasRole(this.user, "ImmunkartePrinter")
    },

    isRezeptCreator() {
      return AuthService.hasRole(this.user, "eRezeptCreator")
    },

    isRezeptProcessor() {
      return AuthService.hasRole(this.user, "eRezeptProcessor")
    },

    isSuperuser() {
      if (!this.user) {
        return false
      } else {
        return this.user.isSuperuser
      }
    }
  },
};
</script>
