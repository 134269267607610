import { createWebHistory, createRouter } from "vue-router";

const routes =  [
    {
        path: "/",
        alias: "/login",
        name: "login",
        component: () => import("./components/AuthSignIn")
    },
    {
        path: "/mandants",
        name: "mandants",
        component: () => import("./components/MandantsList")
    },
    {
        path: "/mandants/:id",
        name: "mandant-details",
        component: () => import("./components/Mandant")
    },
    {
        path: "/mandant-add",
        name: "mandant-add",
        component: () => import("./components/MandantAdd")
    },
    {
        path: "/immunkartes",
        name: "immunkartes",
        component: () => import("./components/ImmunkarteList")
    },
    {
        path: "/immunkarte-add",
        name: "immunkarte-add",
        component: () => import("./components/ImmunkarteAdd")
    },
    {
        path: "/rezepts",
        name: "rezepts",
        component: () => import("./components/RezeptList")
    },
    {
        path: "/rezept-add",
        name: "rezept-add",
        component: () => import("./components/RezeptAdd")
    },
    {
        path: "/user-info",
        name: "user-info",
        component: () => import("./components/AuthUserInfo")
    },
    {
        path: "/users",
        name: "users",
        component: () => import("./components/UserList")
    },
    {
        path: "/user-add",
        name: "user-add",
        component: () => import("./components/UserAdd")
    },
    {
        path: "/users/:id",
        name: "user-details",
        component: () => import("./components/User")
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/', '/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && (!loggedIn || Date.parse(JSON.parse(loggedIn).expiry) < Date.now())) {
        localStorage.removeItem('user')
        next('/login');
    } else {
        next();
    }
});

export default router;