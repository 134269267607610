import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.signIn(user).then(
                user => {
                    if (!user.accessToken) {
                        commit('loginFailure');
                        return Promise.reject(user);
                    } else {
                        commit('loginSuccess', user);
                        return Promise.resolve(user);
                    }
                },
                error => {
                    console.log("login failed")
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },

        logout({ commit }) {
            AuthService.signOut();
            commit('logout');
        },
        
        register({ commit }, user) {
            return AuthService.signUp(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};